import { useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import { InfoCard } from 'component-library'
import { useSetSlideLinkTabIndex } from 'shared-utilities'
import LinkComponent from '../LinkComponent/LinkComponent'
import LoaderSpinner from '../../components/LoaderSpinner/LoaderSpinner'
import { withPrefix } from 'gatsby'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'
import './ItemsCarousel.scss'

export interface IItemsCarouselCard {
  link: string
  label: string
  image?: string
  slug?: string
  description?: string
}

interface IItemsCarouselProps {
  title?: string
  showCarousel?: boolean
  isLoading?: boolean
  relatedItems?: IItemsCarouselCard[]
  imagesHavePrefix?: boolean
  slidesToShow: number
}

const item_placeholder =
  'https://assets.cloud.pennylane.ai/pennylane_website/pages/demonstrations/demo_placeholder.png'

const RelatedItemSlide = ({
  showCarousel,
  item,
  addMutationObserver,
  imagesHavePrefix,
  siteUrl,
}: {
  showCarousel: boolean
  item: IItemsCarouselCard
  addMutationObserver: boolean
  imagesHavePrefix?: boolean
  siteUrl: string
}) => {
  const cardRef = useRef(null)
  const [isVisible, toggleIsVisible] = useState<boolean>(!addMutationObserver)
  useSetSlideLinkTabIndex(cardRef, toggleIsVisible, addMutationObserver)

  const image = !item.image
    ? item_placeholder
    : imagesHavePrefix || !item.image.includes(siteUrl)
    ? withPrefix(item.image)
    : item.image

  return (
    <div
      ref={cardRef}
      className={`RelatedDemoCard ${!showCarousel ? 'mb-6' : 'mb-8'}`}
      data-testid="item-card"
    >
      <LinkComponent href={item.link} tabIndex={isVisible ? 0 : -1}>
        <InfoCard label={item.label} image={image} className="DemoCard" />
      </LinkComponent>
    </div>
  )
}

const ItemsCarousel = ({
  relatedItems,
  title,
  showCarousel = true,
  imagesHavePrefix,
  isLoading,
  slidesToShow,
}: IItemsCarouselProps) => {
  const slickSettings: Settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    accessibility: true,
    adaptiveHeight: true,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const { siteUrl } = useSiteMetadata()

  const items = relatedItems?.map((item, index) => (
    <RelatedItemSlide
      key={index}
      showCarousel={showCarousel}
      item={item}
      addMutationObserver={relatedItems.length > 3 && showCarousel}
      imagesHavePrefix={imagesHavePrefix}
      siteUrl={siteUrl}
    />
  ))

  if (!relatedItems || relatedItems?.length === 0) {
    return null
  }

  return (
    <section className="ItemsCarousel">
      <>
        {title && <h2 className="ItemsCarousel__title">{title}</h2>}
        {isLoading && <LoaderSpinner />}
        {relatedItems && relatedItems?.length > 3 && showCarousel ? (
          <div
            className="ItemsCarousel__Container"
            data-testid="related-items-carousel"
          >
            <Slider {...slickSettings}>{items}</Slider>
          </div>
        ) : (
          <div className="Items__container">{items}</div>
        )}
      </>
    </section>
  )
}

export default ItemsCarousel
