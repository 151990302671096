import { useMemo } from 'react'
import ItemsCarousel from '../ItemsCarousel/ItemsCarousel'
import { ContentType } from '../../context/GenericSearchContext/GenericSearchContext.constants'
import { useRelatedContentQuery } from '../../providers/ApolloProvider/GlassOnion/hooks'

interface IRelatedBlogsProps {
  slug: string
}

const RelatedBlogs = ({ slug }: IRelatedBlogsProps) => {
  const { data: relatedContent, loading: isLoadingRelatedContent } =
    useRelatedContentQuery(slug, ContentType.BLOG, [ContentType.BLOG])

  // Formatting incoming data, subject to removal after synchronizing the types across components
  const formattedRelatedContent = useMemo(() => {
    return relatedContent && relatedContent.content?.related.length > 0
      ? relatedContent.content.related
          .map((related) => ({
            link: related.link,
            label: related.title,
            image: related.thumbnail,
            slug: related.slug,
            publishedAt: related.publishedAt,
          }))
          .sort(
            (a, b) =>
              new Date(b.publishedAt).valueOf() -
              new Date(a.publishedAt).valueOf()
          )
      : []
  }, [relatedContent])

  return formattedRelatedContent.length > 0 ? (
    <div className="RelatedItemsSection mt-[88px]">
      <ItemsCarousel
        title="Related Blog Posts"
        relatedItems={formattedRelatedContent}
        isLoading={isLoadingRelatedContent}
        slidesToShow={3}
      />
    </div>
  ) : null
}

export default RelatedBlogs
