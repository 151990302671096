import './LoaderSpinner.scss'

const LoaderSpinner = ({
  addClass = '',
  type = 'primary',
}: {
  addClass?: string
  type?: 'white' | 'primary'
}) => {
  return (
    <div
      data-testid="loader-spinner"
      className={`LoaderSpinner LoaderSpinner--${type} ${addClass}`}
    >
      <div className="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default LoaderSpinner
